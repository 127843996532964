interface AboutContextProps {
  text: string;
}

function AboutContext({ text }: AboutContextProps) {
  const words = text.split(' ');
  const firstTwoWords = words.slice(0, 2).join(' ');
  const restOfText = words.slice(2).join(' ');

  return (
    <div className="custom-height-mq:text-justify text-center w-[19.125rem] custom-height-mq:w-full">
      <span className="text-xs custom-height-mq:text-xl font-bold text-[#1C4585]">
        {firstTwoWords}
      </span>
      <span> </span>
      <span className="text-xs custom-height-mq:text-xl font-bold text-[#1C4585]">
        {restOfText}
      </span>
    </div>
  );
}

export default AboutContext;
