import { useEffect, useState } from 'react';
import IconButton from './IconButton';
import ProductsCard from './ProductsCard/ProductsCard';
import ApplicationsCard from './ApplicationsCard/ApplicationsCard';
import SystemsCard from './SystemsCard/SystemsCard';
import ExpandingDots from './ExpandingDots';
import LeftLinedArrowIcon from '../assets/LeftLinedArrow.svg';
import RightLinedArrowIcon from '../assets/RightLinedArrow.svg';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';

interface CarouselProps {
  items: Array<any>;
  type: 'products' | 'applications' | 'systems';
}

export default function Carousel({ items, type }: CarouselProps) {
  const [currentGroupIndex, setCurrentGroupIndex] = useState(0);
  const [outgoingAnimation, setOutgoingAnimation] = useState('');
  const [incomingAnimation, setIncomingAnimation] = useState('');
  const [animationKey, setAnimationKey] = useState(0);
  const [productsPerGroup, setProductsPerGroup] = useState(4);
  const totalGroups = Math.ceil(items.length / productsPerGroup);
  const currentLanguage = useSelector(
    (state: RootState) => state.language.language
  );

  useEffect(() => {
    const handleResize = () => {
      if (type === 'systems' || window.innerWidth < 768) {
        setProductsPerGroup(1);
      } else {
        setProductsPerGroup(4);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [type]);

  const updateAnimationClasses = (nextIndex: number) => {
    if (nextIndex > currentGroupIndex) {
      setOutgoingAnimation('slide-out-left');
      setIncomingAnimation('slide-in-right');
    } else {
      setOutgoingAnimation('slide-out-right');
      setIncomingAnimation('slide-in-left');
    }
    setAnimationKey((prevKey) => prevKey + 1);
  };

  const handleNext = () => {
    if (currentGroupIndex < totalGroups - 1) {
      updateAnimationClasses(currentGroupIndex + 1);
      setCurrentGroupIndex(currentGroupIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentGroupIndex > 0) {
      updateAnimationClasses(currentGroupIndex - 1);
      setCurrentGroupIndex(currentGroupIndex - 1);
    }
  };

  const handleDotChange = (newIndex: number) => {
    if (newIndex === currentGroupIndex) {
      return;
    }
    updateAnimationClasses(newIndex);
    setCurrentGroupIndex(newIndex);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setOutgoingAnimation('');
      setIncomingAnimation('');
    }, 500);
    return () => clearTimeout(timeout);
  }, [currentGroupIndex, animationKey]);

  const renderCards = () => {
    const start = currentGroupIndex * productsPerGroup;
    const end = start + productsPerGroup;
    return items
      .slice(start, end)
      .map((item, index) => renderCard(item, index + start));
  };

  const renderCard = (item: any, index: number) => {
    switch (type) {
      case 'applications':
        return (
          <ApplicationsCard
            key={index}
            id={item.id}
            image={item['home_page_card_image'][0].image}
            title={item.title}
            description={item.description}
          />
        );
      case 'systems':
        return (
          <SystemsCard
            key={index}
            id={item.id}
            image={item['home_page_card_image'][0].image}
            title={item.title}
            description={item.description}
          />
        );
      case 'products':
      default:
        return (
          <ProductsCard
            key={index}
            id={item.id}
            title={item['title']}
            subtitle={item['subtitle']}
            description={item['description']}
            weight={item['weight']}
            unitOfMeasure={item['unit_of_measure']}
            coverageRate={item['coverage_rate']}
            coverageRateUnit={item['coverage_rate_unite']}
            howToApply={item['how_to_apply']}
            cardImage={item['card_image']['image']}
            descriptionAdvantages={item['description_advantages']}
            pdf={item['pdf']}
            places={item['places']}
            video={item['videos'][0]['video_url']}
          />
        );
    }
  };

  return (
    <>
      <div className="flex items-center gap-5">
        {totalGroups > 1 && (
          <IconButton
            Icon={
              currentLanguage === 'ar'
                ? RightLinedArrowIcon
                : LeftLinedArrowIcon
            }
            onClick={handlePrev}
          />
        )}
        <div className="flex justify-center items-center overflow-hidden">
          <div
            key={animationKey}
            className={`transform transition-transform flex gap-4 ${outgoingAnimation} ${incomingAnimation}`}
          >
            {renderCards()}
          </div>
        </div>
        {totalGroups > 1 && (
          <IconButton
            Icon={
              currentLanguage === 'ar'
                ? LeftLinedArrowIcon
                : RightLinedArrowIcon
            }
            onClick={handleNext}
          />
        )}
      </div>
      <ExpandingDots
        totalDots={totalGroups}
        activeDot={currentGroupIndex}
        onChange={handleDotChange}
      />
    </>
  );
}
