import { useState } from 'react';
import { ReactComponent as SemiblueArrowDownload } from '../../Home/assets/SemiBlueArrowDownload.svg';
import { ReactComponent as SemiblueTopArrow } from '../../Home/assets/BlueTopArrowIcon.svg';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';

interface PagesHeaderProps {
  title: string;
  icon?: string;
  ContactUs?: boolean;
  href?: string;
}

function PagesHeader({ title, icon, ContactUs, href }: PagesHeaderProps) {
  const currentLanguage = useSelector(
    (state: RootState) => state.language.language
  );
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const toValues: { [key: string]: string } = {
    'About Us': '/about',
    Home: '',
    'Contact Us': '/contact',
    Applications: '/applications',
    Downloads: '/downloads',
    Products: '/products',
    Videos: '/videos',
    Systems: '/systems',
    Awards: '/awards',
  };

  const toValue = `/${currentLanguage}${
    toValues[title] || `/${currentLanguage}`
  }`;

  const linkProps = {
    className: `text-left flex flex-row items-center justify-start gap-1 ${
      ContactUs
        ? 'text-[#868686] text-xs custom-height-mq:text-sm'
        : 'text-[#FFFFFF] text-sm'
    }  transition-all hover:scale-[1.1] hover:cursor-pointer hover:text-[#1EBAE5]`,
    onMouseEnter: handleMouseEnter,
    onMouseLeave: handleMouseLeave,
  };

  return href ? (
    <a {...linkProps} href={href} target="_blank">
      {ContactUs ? <>{title} &#11008;</> : title}
      {icon && !ContactUs ? (
        isHovered ? (
          title === t('Downloads') ? (
            <SemiblueArrowDownload className="w-[0.6125rem] h-[0.6125rem]" />
          ) : (
            <SemiblueTopArrow className="w-[0.6125rem] h-[0.6125rem]" />
          )
        ) : (
          <img
            src={icon}
            alt="Dropdown"
            className="w-[0.6125rem] h-[0.6125rem]"
            loading="lazy"
          />
        )
      ) : (
        <></>
      )}
    </a>
  ) : (
    <Link to={toValue} {...linkProps}>
      {ContactUs ? <>{title} &#11008;</> : title}
      {icon && !ContactUs ? (
        isHovered ? (
          title === t('Downloads') ? (
            <SemiblueArrowDownload className="w-[0.6125rem] h-[0.6125rem]" />
          ) : (
            <SemiblueTopArrow className="w-[0.6125rem] h-[0.6125rem]" />
          )
        ) : (
          <img
            src={icon}
            alt="Dropdown"
            className="w-[0.6125rem] h-[0.6125rem]"
            loading="lazy"
          />
        )
      ) : (
        <></>
      )}
    </Link>
  );
}

export default PagesHeader;
