interface StyledHeaderProps {
  title: string;
  Awards?: boolean;
}

function StyledHeader({ title, Awards }: StyledHeaderProps) {
  const words = title.split(' ');
  const firstWord = words.shift();
  const secondWord = words.shift();
  const restOfTitle = words.join(' ');

  const commonStylesNormal = `${
    Awards ? '!arabicLanguage !text-sm custom-height-mq:!text-2xl' : ''
  } font-semibold text-xl custom-height-mq:text-4xl leading-13 text-[#1C4585]`;
  const commonStylesBold = `${
    Awards ? '!arabicLanguage !text-sm custom-height-mq:!text-2xl' : ''
  } font-semibold text-xl custom-height-mq:text-4xl leading-13 text-[#1C4585]`;

  return (
    <span
      className={`text-center ${Awards ? '!arabicLanguage mb-[2rem]' : ''}`}
    >
      {((first, second, rest) => {
        if (first === 'Our') {
          return (
            <div className="border-b-[0.3rem] pb-[0.5rem] border-[#FBAD4A] border-solid">
              <span className={commonStylesNormal}>{title}</span>
            </div>
          );
        } else if (first === 'Chema' && second === 'Foam') {
          return (
            <div className="border-b-[0.3rem] pb-[0.5rem] border-[#FBAD4A] border-solid">
              <span className={commonStylesBold}>
                {first} {second}
              </span>
              <span className={commonStylesBold}> </span>
              <span className={commonStylesNormal}>{rest}</span>
            </div>
          );
        } else {
          return (
            <div className="border-b-[0.3rem] pb-[0.5rem] border-[#FBAD4A] border-solid">
              <span className={commonStylesBold}>{first}</span>
              <span className={commonStylesBold}> </span>
              <span className={commonStylesNormal}>
                {second} {rest}
              </span>
            </div>
          );
        }
      })(firstWord, secondWord, restOfTitle)}
    </span>
  );
}

export default StyledHeader;
