import { useState } from 'react';
import { Link } from 'react-router-dom';
import MobileDrawer from '../Header/MobileDrawer';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';

interface MobileBottomNavBarProps {
  icons: string[];
  titles: string[];
  active: string;
  awards?: boolean;
}

function MobileBottomNavBar({
  icons,
  titles,
  active,
  awards,
}: MobileBottomNavBarProps) {
  const currentLanguage = useSelector(
    (state: RootState) => state.language.language
  );
  const [drawerVisible, setDrawerVisible] = useState(false);
  const { t } = useTranslation();

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };
  const toValues: { [key: string]: string } = {
    [t('Home')]: '',
    [t('Contact Us')]: '/contact',
    [t('Applications')]: '/applications',
    [t('Products')]: '/products',
    'الشروط والأحكام': '/awards',
    'جوائز المسابقة': '/prizes',
    'التسجيل في البرنامج': '/register',
    'تسجيل الدخول': '/login',
  };

  return (
    <div className="fixed bottom-0 w-screen bg-[#1F3566] bg-opacity-[0.85] h-[3rem] block custom-height-mq:hidden z-50">
      <div className="flex justify-between items-center h-full">
        {titles.map((title, index) => (
          <Link
            to={`/${currentLanguage}${
              toValues[title] || `/${currentLanguage}`
            }`}
            key={index}
            className={`flex flex-col justify-center items-center ${
              awards ? 'w-[25%]' : 'w-[20%]'
            } h-full ${active === title ? 'bg-[#FBAD4A] bg-opacity-100' : ''}`}
            onClick={title === t('More') ? showDrawer : undefined}
          >
            <img
              src={icons[index]}
              alt="Mobile Nav Link"
              className={`w-[1rem] h-[1rem] ${
                active === title ? 'bottomNavBarIconsFilter' : ''
              }`}
            />
            <span
              className={`text-${
                active === title ? '[#1C4585]' : '[#D7D7D7]'
              } ${
                awards ? '!arabicLanguage' : ''
              } text-[0.55rem] leading-[0.91rem]`}
            >
              {title}
            </span>
          </Link>
        ))}
        <MobileDrawer visible={drawerVisible} onClose={closeDrawer} />
      </div>
    </div>
  );
}

export default MobileBottomNavBar;
