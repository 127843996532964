import { useState } from 'react';
import { ReactComponent as AWARD } from '../assets/BlueAwards.svg';
import { ReactComponent as APPS } from '../assets/BlueApps.svg';
import { ReactComponent as Products } from '../assets/BlueProducts.svg';
import { ReactComponent as Videos } from '../assets/BlueVideo.svg';
import { ReactComponent as Downloads } from '../assets/BlueArrowDownload.svg';
import { ReactComponent as Contact } from '../assets/BlueContactUs.svg';
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';

interface ContextCardProps {
  title: string;
  icon: string;
  footer?: boolean;
  LinkTo: string;
}

function ContextCard({ title, icon, footer, LinkTo }: ContextCardProps) {
  const currentLanguage = useSelector(
    (state: RootState) => state.language.language
  );
  const [hovered, setHovered] = useState(false);
  const [iconBlue, setIcon] = useState<any>('');
  const { t } = useTranslation();

  const handleMouseEnter = () => {
    setHovered(true);

    switch (t(title)) {
      case t('AWARDS'):
        setIcon(AWARD);
        break;
      case t('APPLICATIONS'):
        setIcon(APPS);
        break;
      case t('PRODUCTS'):
        setIcon(Products);
        break;
      case t('VIDEOS'):
        setIcon(Videos);
        break;
      case t('DOWNLOADS'):
        setIcon(Downloads);
        break;
      case t('CONTACT US'):
        setIcon(Contact);
        break;
      default:
        setIcon('');
    }
  };
  const toValues: { [key: string]: string } = {
    'About Us': '/about',
    Home: '',
    'Contact Us': '/contact',
    Applications: '/applications',
    Downloads: '/downloads',
    Products: '/products',
    Videos: '/videos',
    Systems: '/systems',
    Awards: '/awards',
  };

  const toValue = `/${currentLanguage}${
    toValues[LinkTo] || `/${currentLanguage}`
  }`;

  const handleMouseLeave = () => {
    setHovered(false);
    setIcon('');
  };

  return (
    <Link
      to={toValue}
      className={`flex group flex-row cursor-pointer relative hover:bg-[#FBAD4A] hover:opacity-100 bg-white ${
        footer ? '' : 'bottom-[7rem]'
      } items-center justify-center gap-1 ${
        footer
          ? 'w-[5.75rem] h-[2.75rem] custom-height-mq:w-[8.44rem] custom-height-mq:h-[3.75rem] opacity-80'
          : 'w-[5.625rem] h-[2.5rem] custom-height-mq:w-[11.25rem] custom-height-mq:h-[4.44rem]'
      } ContextCardShadow`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {!hovered ? (
        <img
          src={icon}
          alt="Footer"
          className={` ${
            footer
              ? 'w-[1.0225rem] h-[0.724375rem] custom-height-mq:w-[1.5rem] custom-height-mq:h-[1.03rem]'
              : 'w-[1.15625rem] h-[1rem] custom-height-mq:w-[2.00125rem] custom-height-mq:h-[1.375rem]'
          }`}
          loading="lazy"
        />
      ) : (
        iconBlue && (
          <React.Fragment>
            <div className="hidden custom-height-mq:block">
              {React.createElement(iconBlue, {
                width: footer ? '1.5rem' : '2.00125rem',
                height: footer ? '1.03rem' : '1.375rem',
              })}
            </div>
            <div className="custom-height-mq:hidden">
              {React.createElement(iconBlue, {
                width: footer ? '1.0225rem' : '1.15625rem',
                height: footer ? '0.724375rem' : '1rem',
              })}
            </div>
          </React.Fragment>
        )
      )}
      <span
        className={`font-bold text-[#868686] group-hover:text-[#1C4585] ${
          currentLanguage === 'ar' ? 'mb-[0.3rem]' : ''
        } ${
          footer
            ? 'text-[0.5rem] leading-[1rem] custom-height-mq:text-xs'
            : 'text-[0.5rem] leading-[1rem] custom-height-mq:text-base'
        } leading-8 lg:leading-10 xl:leading-12 text-center`}
      >
        {title}
      </span>
    </Link>
  );
}

export default ContextCard;
