import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enTranslation from './en.json';
import arTranslation from './ar.json';

const resources = {
  en: {
    translation: enTranslation,
  },
  ar: {
    translation: arTranslation,
  },
};

const pathLanguageDetector = {
  name: 'pathLanguageDetector',
  lookup() {
    const pathSegments = window.location.pathname.split('/');
    const lang = pathSegments[1];
    return lang || localStorage.getItem('i18nextLng') || 'en';
  },
  cacheUserLanguage(lang: string) {
    localStorage.setItem('i18nextLng', lang);
  },
};

const languageDetector = new LanguageDetector();
languageDetector.addDetector(pathLanguageDetector);

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    debug: false,
    detection: {
      order: ['pathLanguageDetector', 'localStorage', 'navigator'],
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
