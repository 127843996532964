import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  useLocation,
  useNavigate,
  Navigate,
} from 'react-router-dom';
import SlideRoutes from 'react-slide-routes';
import { setLanguage } from './i18next/langSlice';
import Home from './Pages/Home/Home';
import About from './Pages/About Us/About';
import Contact from './Pages/Contact Us/contact';
import Applications from './Pages/Applications/Applications';
import Downloads from './Pages/Downloads/Downloads';
import Products from './Pages/Products/Products';
import Systems from './Pages/Systems/Systems';
import Videos from './Pages/Videos/Videos';
import TermsAndConditions from './Pages/Awards/Terms&Conditions/Terms&Conditions';
import Prizes from './Pages/Awards/Prizes/Prizes';
import Login from './Pages/Awards/LoginToTheProgram/LoginToTheProgram';
import Register from './Pages/Awards/RegisterInTheProgram/RegisterInTheProgram';
import CopounGiftCard from './Pages/Awards/CopounGiftCard/CopounGiftCard';
import RevisePoints from './Pages/Awards/RevisePoints/RevisePoints';
import ScrollToTop from './ScrollToTop';
import UpdateProfile from './Pages/Awards/UpdateProfile/UpdateProfile';
import Blogs from './Pages/Blogs/blogs';
import BlogsDetails from './Pages/Blogs/[slug]/blogsDetails';

function AppRoutes() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [isLanguageSet, setIsLanguageSet] = useState(false);
  const supportedLanguages = ['en', 'ar'];

  const getCachedLanguage = () => {
    return localStorage.getItem('preferredLanguage') || 'en';
  };

  const isValidLanguage = (pathLang : any) => supportedLanguages.includes(pathLang);

  useEffect(() => {
    const handleLanguageChange = async () => {
      const pathLang = location.pathname.split('/')[1];

      if (supportedLanguages.includes(pathLang)) {
        await i18n.changeLanguage(pathLang);
        await dispatch(setLanguage(pathLang));
        localStorage.setItem('preferredLanguage', pathLang); 
        setIsLanguageSet(true);
      } else {
        const cachedLang = getCachedLanguage();
        await i18n.changeLanguage(cachedLang);
        await dispatch(setLanguage(cachedLang));
        setIsLanguageSet(true);
        navigate(`/${cachedLang}`, { replace: true }); 
      }

      document.documentElement.lang = i18n.language;
      document.documentElement.dir = i18n.language === 'ar' ? 'rtl' : 'ltr';
    };

    handleLanguageChange();
  }, [location, i18n, navigate, dispatch]);

  if (!isLanguageSet) {
    return null;
  }

  return (
    <SlideRoutes duration={800}>
      <Route
        path="*"
        element={
          isValidLanguage(location.pathname.split('/')[1]) ? (
            <Navigate to={`${location.pathname}`} replace />
          ) : (
            <Navigate to={`/${getCachedLanguage()}`} replace />
          )
        }
      />

      <Route path="/:lang" element={<Home />} />
      <Route path="/:lang/about" element={<About />} />
      <Route path="/:lang/applications" element={<Applications />} />
      <Route path="/:lang/products" element={<Products />} />
      <Route path="/:lang/systems" element={<Systems />} />
      <Route path="/:lang/videos" element={<Videos />} />
      <Route path="/:lang/downloads" element={<Downloads />} />
      <Route path="/:lang/blogs" element={<Blogs />} />
      <Route path="/:lang/blogs/:slug" element={<BlogsDetails />} />
      <Route path="/:lang/contact" element={<Contact />} />
      <Route path="/:lang/awards" element={<TermsAndConditions />} />
      <Route path="/:lang/prizes" element={<Prizes />} />
      <Route path="/:lang/register" element={<Register />} />
      <Route path="/:lang/login" element={<Login />} />
      <Route path="/:lang/copoun" element={<CopounGiftCard />} />
      <Route path="/:lang/points" element={<RevisePoints />} />
      <Route path="/:lang/updateProfile" element={<UpdateProfile />} />
    </SlideRoutes>
  );
}

function App() {
  return (
    <Router>
      <AppRoutes />
      <ScrollToTop />
    </Router>
  );
}

export default App;
