import React from 'react';
import CardContext from '../ApplicationsCard/CardContext';
import CardDataContent from '../ApplicationsCard/CardDataContent';
import CardDataHeader from '../ApplicationsCard/CardDataHeader';
import NonExpandingButton from '../ApplicationsCard/NonExpandingButton';
import { useTranslation } from 'react-i18next';

interface BlogsCardProbs {
  image: string;
  title: string;
  description: string;
  id: number;
}

function BlogsCard({ image, title, description, id }: BlogsCardProbs) {
  const { t } = useTranslation();
  return (
    <div className="group relative justify-between items-center flex flex-col w-[13.625rem] custom-height-mq:w-[17.0625rem] h-[21.125rem] hover:bg-[#1C4585] hover:border-[#1C4585] hover:ContextCardShadow hover:border-opacity-10 hover:opacity-80 border-2 duration-1000 transform border-solid border-[#FBAD4A]">
      <img
        src={image}
        alt="Chemo Workers"
        className="w-full h-[80%] duration-500 transform group-hover:h-[7058%] object-cover mix-blend-overlay"
        loading="lazy"
      />
      <CardContext title={title} />
      <div className="absolute p-5 gap-5 w-full h-full flex flex-col items-center justify-center opacity-0 transition-opacity duration-500 ease-in-out group-hover:opacity-100">
        <div className="flex flex-col items-center justify-center">
          <CardDataHeader title={title} />
          <CardDataContent title={description} />
        </div>
        <div>
          <NonExpandingButton
            title={t('View Blog')}
            bgColor="bg-[#D7D7D7]"
            hoverBgColor="bg-[#FBAD4A]"
            textColor="text-[#1C4585]"
            hoverTextColor="text-[#1C4585]"
            IconType="None"
            id={id}
            slug={id}
          />
        </div>
      </div>
    </div>
  );
}

export default React.memo(BlogsCard);
