import ContactUsButton from '../Main components/ContactUsButton';
import Header from '../Main components/Header/Header';
import MainFooter from '../Main components/MainFooter/MainFooter';
import MainHeader from '../Main components/MainHeader/MainHeader';
import MobileBottomNavBar from '../Main components/MobileBottomNavBar/MobileBottomNavBar';
import NavigateTopButton from '../Main components/NavigateTopButton';
import HouseIcon from '../Main components/MobileBottomNavBar/assets/HomeIcon.svg';
import AppIcon from '../Main components/MobileBottomNavBar/assets/AppsIcon.svg';
import ProductIcon from '../Main components/MobileBottomNavBar/assets/ProductIcon.svg';
import ContactIcon from '../Main components/MobileBottomNavBar/assets/ContactIcon.svg';
import MoreIcon from '../Main components/MobileBottomNavBar/assets/MoreIcon.svg';
import { useTranslation } from 'react-i18next';
import BlogsCard from '../Home/components/BlogsCard/BlogsCard';
import { useGetBlogsDataQuery } from '../../store/apiService';
import { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export default function Blogs() {
  const { t, i18n } = useTranslation();

  const BottomNavBarIcons = [
    HouseIcon,
    AppIcon,
    ProductIcon,
    ContactIcon,
    MoreIcon,
  ];
  const BottomNavBarTitles = [
    t('Home'),
    t('Applications'),
    t('Products'),
    t('Contact Us'),
    t('More'),
  ];

  const gridDirection = i18n.language === 'ar' ? 'rtl' : 'ltr';

  const {
    data: blogsData,
    isFetching,
    error,
    refetch,
  } = useGetBlogsDataQuery(undefined);

  useEffect(() => {
    refetch();
  }, [i18n.language, refetch]);
  console.log(isFetching);
  return (
    <div className="flex flex-col items-center">
      <Header Active="blogs" />
      <MobileBottomNavBar
        icons={BottomNavBarIcons}
        titles={BottomNavBarTitles}
        active={t('blogs')}
      />
      <NavigateTopButton />
      <ContactUsButton />
      <MainHeader title={t('blogs')} page={t('blogs')} />

      <div
        className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 p-8 lg:p-32`}
        style={{ direction: gridDirection }}
      >
        {isFetching ? (
          Array.from({ length: 8 }).map((_, index) => (
            <div key={index} className="w-[270px]">
              <Skeleton height={290} />
              <Skeleton count={2} />
            </div>
          ))
        ) : error ? (
          <p>{t('Error loading blogs')}</p>
        ) : (
          blogsData?.blogs?.map((blog: any) => (
            <BlogsCard
              key={blog.id}
              id={blog.id}
              image={blog.card_image}
              title={blog.title}
              description={blog.description.replace(/<\/?[^>]+(>|$)/g, '')}
            />
          ))
        )}
      </div>

      <MainFooter />
    </div>
  );
}
