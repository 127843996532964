import PrizesIcon from './assets/Prizes.svg';
import ChemaFoamIcon from './assets/ChemoFoam.svg';
import CopyRightIcon from './assets/Copyright.svg';
import TermsAndConditionsIcon from './assets/TermsAndConditionsIcon.svg';
import CopounGiftCardIcon from './assets/CopounGiftCard.svg';
import RevisePointsIcon from './assets/RevisePoints.svg';
import { Link } from 'react-router-dom';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';

interface SideBarActiveProps {
  Active?: string;
}

function SideBar({ Active }: SideBarActiveProps) {
  const loggedin = useSelector((state: RootState) => state.auth.loggedin);
  const currentLanguage = useSelector(
    (state: RootState) => state.language.language
  );

  return (
    <div className="fixed rtl py-[5rem] right-0 w-[18.125rem] custom-height-mq:flex hidden flex-col items-center justify-center h-[-webkit-fill-available] bg-[#1C4585]">
      <div className="flex flex-col justify-center items-start gap-6">
        {loggedin && (
          <>
            <Link
              to={`/${currentLanguage}/points`}
              className="flex flex-row gap-2 hover:cursor-pointer"
            >
              <img
                src={RevisePointsIcon}
                alt="Revise"
                className={`w-[1.5rem] h-[1.5rem] ${
                  Active === 'مراجعة رصيد النقاط' ? 'awardsIconFilter' : ''
                } `}
              />
              <div
                className={`${
                  Active === 'مراجعة رصيد النقاط' ? '!text-[#FBAD4A]' : ''
                } text-base font-[500] text-white arabicLanguage`}
              >
                مراجعة رصيد النقاط
              </div>
            </Link>
            <Link
              to={`/${currentLanguage}/copoun`}
              className="flex flex-row gap-2 hover:cursor-pointer"
            >
              <img
                src={CopounGiftCardIcon}
                alt="Prizes"
                className={`w-[1.5rem] h-[1.5rem] ${
                  Active === 'إدخال كارت الخدش' ? 'awardsIconFilter' : ''
                } `}
              />
              <div
                className={`${
                  Active === 'إدخال كارت الخدش' ? '!text-[#FBAD4A]' : ''
                } text-base font-[500] text-white arabicLanguage`}
              >
                إدخال كارت الخدش
              </div>
            </Link>
          </>
        )}
        <Link
          to={`/${currentLanguage}/prizes`}
          className="flex flex-row gap-2 hover:cursor-pointer"
        >
          <img
            src={PrizesIcon}
            alt="Prizes"
            className={`w-[1.5rem] h-[1.5rem] ${
              Active === 'جوائز المسابقه' ? 'awardsIconFilter' : ''
            } `}
          />
          <div
            className={`${
              Active === 'جوائز المسابقه' ? '!text-[#FBAD4A]' : ''
            } text-base font-[500] text-white arabicLanguage`}
          >
            جوائز المسابقه
          </div>
        </Link>
        <Link
          to={`/${currentLanguage}/awards`}
          className="flex flex-row gap-2 hover:cursor-pointer"
        >
          <img
            src={TermsAndConditionsIcon}
            alt="Prizes"
            className={`w-[1.5rem] h-[1.5rem] mr-[-3px] ${
              Active === 'الشروط  والأحكام' ? 'awardsIconFilter' : ''
            }`}
          />
          <div
            className={`${
              Active === 'الشروط  والأحكام' ? '!text-[#FBAD4A]' : ''
            } text-base font-[500] text-white arabicLanguage`}
          >
            الشروط والأحكام
          </div>
        </Link>
      </div>
      <div className="flex-grow" />
      <div className="flex flex-col gap-5 justify-center items-center">
        <img
          src={ChemaFoamIcon}
          alt="Chema Foam"
          className="w-[7.5625rem] h-[6.5625rem]"
        />
        <img
          src={CopyRightIcon}
          alt="Copyright"
          className="w-[8.575rem] h-[0.5625rem]"
        />
      </div>
    </div>
  );
}

export default SideBar;
