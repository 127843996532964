import { useParams } from 'react-router-dom';
import ContactUsButton from '../../Main components/ContactUsButton';
import Header from '../../Main components/Header/Header';
import MainFooter from '../../Main components/MainFooter/MainFooter';
import MainHeader from '../../Main components/MainHeader/MainHeader';
import MobileBottomNavBar from '../../Main components/MobileBottomNavBar/MobileBottomNavBar';
import NavigateTopButton from '../../Main components/NavigateTopButton';
import HouseIcon from '../../Main components/MobileBottomNavBar/assets/HomeIcon.svg';
import AppIcon from '../../Main components/MobileBottomNavBar/assets/AppsIcon.svg';
import ProductIcon from '../../Main components/MobileBottomNavBar/assets/ProductIcon.svg';
import ContactIcon from '../../Main components/MobileBottomNavBar/assets/ContactIcon.svg';
import MoreIcon from '../../Main components/MobileBottomNavBar/assets/MoreIcon.svg';
import { useTranslation } from 'react-i18next';
import { useGetBlogDetailsQuery } from '../../../store/apiService';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export default function BlogsDetails() {
  const { slug } = useParams();
  const { t, i18n } = useTranslation();

  const BottomNavBarIcons = [
    HouseIcon,
    AppIcon,
    ProductIcon,
    ContactIcon,
    MoreIcon,
  ];
  const BottomNavBarTitles = [
    t('Home'),
    t('Applications'),
    t('Products'),
    t('Contact Us'),
    t('More'),
  ];

  const contentDirection = i18n.language === 'ar' ? 'rtl' : 'ltr';

  const { data: blogData, isFetching, error } = useGetBlogDetailsQuery(slug);

  return (
    <div className="flex flex-col items-center">
      <Header Active="blogs details" />
      <MobileBottomNavBar
        icons={BottomNavBarIcons}
        titles={BottomNavBarTitles}
        active={t('blogs details')}
      />
      <NavigateTopButton />
      <ContactUsButton />
      <MainHeader title={t('blogs')} page={t('blogs')} />

      <div
        className="p-9 lg:p-16 flex flex-col items-center gap-14"
        style={{ direction: contentDirection }}
      >
        {isFetching ? (
          <div className="w-full">
            <Skeleton height={700} width="100%" />
          </div>
        ) : error ? (
          <p>{t('Error loading blog details')}</p>
        ) : (
          <>
            <img
              src={blogData?.blogs?.header_image}
              alt={blogData?.blogs?.title}
              className="w-full h-auto rounded-lg shadow-md"
            />

            <div className="w-full">
              <h1 className="text-4xl font-bold mb-4">
                {blogData?.blogs?.title}
              </h1>

              <div
                className="text-lg text-gray-700"
                dangerouslySetInnerHTML={{
                  __html: blogData?.blogs?.description,
                }}
              ></div>

              <div className="mt-8">
                <h2 className="text-3xl font-semibold mb-4">{t('Details')}</h2>

                {blogData?.blogs?.blog_details?.map((detail: any) => (
                  <div key={detail.id} className="mb-6">
                    <h3 className="text-2xl font-bold mb-2">{detail.title}</h3>
                    <div
                      className="text-lg text-gray-700"
                      dangerouslySetInnerHTML={{ __html: detail.description }}
                    ></div>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>

      <MainFooter />
    </div>
  );
}
