import ChemaGroupIcon from '../../Home/assets/ChemaGroup.webp';
import ContextCard from '../../Home/components/ContextCard';
import AwardsIcon from '../../Home/assets/Awards.svg';
import AppsIcon from '../../Home/assets/Apps.svg';
import ArrowDownloadIcon from '../../Home/assets/ArrowDownload.svg';
import ProductsIcon from '../../Home/assets/Products.svg';
import VideosIcon from '../../Home/assets/Video.svg';
import ContactUsIcon from '../../Home/assets/Message.svg';
import FooterHeader from './FooterHeader';
import PagesHeader from './PagesHeader';
import WhiteArrowDownloadIcon from '../../Home/assets/WhiteDownloadIcon.svg';
import WhiteArrowTopIcon from '../../Home/assets/ArrowTopWhite.svg';
import FacebookGreyIcon from '../../Home/assets/FacebookGreyIconVisible.svg';
import LinkedInGreyIcon from '../../Home/assets/LinkedInGreyIcon.svg';
import YoutubeGreyIcon from '../../Home/assets/YoutubeGreyIcon.svg';
import SocialMediaIcon from './SocialMediaIcon';
import CallUsPhoneIcon from '../../Home/assets/OrangePhoneCall.svg';
import Copyright from '../../Home/assets/Copyright.svg';
import ChemaFoamIcon from '../../Home/assets/ChemoFoam.svg';
import CallUsIcon from './CallUsIcon';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { useGetPageDataQuery } from '../../../store/apiService';

function MainFooter() {
  const currentLanguage = useSelector(
    (state: RootState) => state.language.language
  );
  const { data, error, isLoading, refetch } = useGetPageDataQuery('contactUs', {
    skip: false,
  });
  const { t } = useTranslation();
  return (
    data && (
      <div className="ltr px-[2rem] custom-height-mq:px-[1.5rem] relative py-[3.75rem] gap-9 w-screen min-h-[27.94rem] flex flex-col items-center justify-center bg-[#1C4585]">
        <div className="flex flex-col gap-12">
          <div className="flex flex-row items-start custom-height-mq:items-center justify-center gap-[2rem] custom-height-mq:gap-[3rem]">
            <img
              src={ChemaGroupIcon}
              alt="Chema Group Icon"
              className="w-[9.0125rem] h-[2.93125rem] custom-height-mq:w-[12.88rem] custom-height-mq:h-[4.19rem]"
              loading="lazy"
            />
            <div className="flex-grow" />
            <div
              className={` ${
                currentLanguage === 'ar' ? 'rtl' : 'ltr'
              } flex flex-col custom-height-mq:flex-row gap-5 custom-height-mq:gap-2 items-center justify-center`}
            >
              <ContextCard
                title={t('AWARDS')}
                icon={AwardsIcon}
                footer={true}
                LinkTo="Awards"
              />
              <ContextCard
                title={t('APPLICATIONS')}
                icon={AppsIcon}
                footer={true}
                LinkTo="Applications"
              />
              <ContextCard
                title={t('PRODUCTS')}
                icon={ProductsIcon}
                footer={true}
                LinkTo="Products"
              />
              <ContextCard
                title={t('VIDEOS')}
                icon={VideosIcon}
                footer={true}
                LinkTo="Videos"
              />
              <ContextCard
                title={t('DOWNLOADS')}
                icon={ArrowDownloadIcon}
                footer={true}
                LinkTo="Downloads"
              />
              <ContextCard
                title={t('CONTACT US')}
                icon={ContactUsIcon}
                footer={true}
                LinkTo="Contact Us"
              />
            </div>
          </div>
          <div className="flex flex-col mt-[-19rem] custom-height-mq:mt-0 custom-height-mq:flex-row justify-center items-start custom-height-mq:items-start gap-[4rem]">
            <div className="flex flex-col items-left justify-center gap-4">
              <FooterHeader title={t('Need Help?')} />
              <div className="flex flex-row gap-[2rem] custom-height-mq:gap-[6rem]">
                <div className="flex flex-col gap-[0.7rem]">
                  <PagesHeader title={t('Home')} />
                  <PagesHeader title={t('About Us')} />
                  <PagesHeader title={t('Applications')} />
                  <PagesHeader title={t('Products')} />
                  <PagesHeader title={t('Systems')} />
                </div>
                <div className="flex flex-col gap-[0.7rem]">
                  <PagesHeader title={t('Videos')} />
                  <PagesHeader
                    title={t('Downloads')}
                    icon={WhiteArrowDownloadIcon}
                  />
                  <PagesHeader title={t('Contact Us')} />
                </div>
              </div>
            </div>
            <div className="flex flex-col items-left justify-center gap-4">
              <FooterHeader title={t('Map Location')} />
              <div className="flex flex-col gap-3 max-w-[300px]">
                <div className="font-bold text-left text-white text-base">
                  {t('FACTORY')}
                </div>
                <PagesHeader
                  title={data['inquiries'][0]['factory_address']}
                  href="https://www.google.com/maps/place/Chema+Foam+Group+-+%D9%83%D9%8A%D9%85%D8%A7+%D9%81%D9%88%D9%85%E2%80%AD/@30.3196073,31.7795342,15z/data=!4m6!3m5!1s0x14f80796d8433167:0xb2ed4d649c3b3c9e!8m2!3d30.3196073!4d31.7795342!16s%2Fg%2F11c1rfd9c4?sa=X&ved=2ahUKEwjb24HwlZD_AhXLTaQEHXS6DfMQ_BJ6BAhMEAg&entry=tts&shorturl=1"
                  icon={WhiteArrowTopIcon}
                />
                <div className="font-bold text-left text-white text-base">
                  {t('OFFICE')}
                </div>
                <PagesHeader
                  title={data['inquiries'][0]['office_address']}
                  href="https://www.google.com/maps/place/TAQA+Misr/@30.078028,31.3113224,21z/data=!4m7!3m6!1s0x14581872016c19c1:0x599141d37b8d1d8d!8m2!3d30.0779342!4d31.3115634!15sCj0xMSBPYm91ciBCdWlsZGluZ3MgLSBTYWxhaCBTYWxlbSAtIE5hc3IgQ2l0eSBDYWlybyAtIEVneXB04oCtkgEaaGVhdGluZ19lcXVpcG1lbnRfc3VwcGxpZXLgAQA!16s%2Fg%2F1q5bpk6s2?entry=tts&shorturl=1"
                  icon={WhiteArrowTopIcon}
                />
              </div>
            </div>
            <div className="flex-grow hidden custom-height-mq:block" />
            <div className="flex flex-col items-left justify-center relative custom-height-mq:right-[15rem] gap-4">
              <FooterHeader title={t('Follow Us')} />
              <div className="flex flex-col gap-3">
                <div className="flex flex-row gap-5">
                  <SocialMediaIcon
                    link={data['inquiries'][0]['facebook_page_url']}
                    icon={FacebookGreyIcon}
                  />
                  <SocialMediaIcon
                    link={data['inquiries'][0]['linkedin_page_url']}
                    icon={LinkedInGreyIcon}
                  />
                  <SocialMediaIcon
                    link={data['inquiries'][0]['youtube_page_url']}
                    icon={YoutubeGreyIcon}
                  />
                </div>
                <div className="flex-grow" />
                <CallUsIcon icon={CallUsPhoneIcon} hotLine={'0221276230'} />
              </div>
            </div>
          </div>
          <div className="flex flex-row">
            <img
              src={Copyright}
              alt="Copyright"
              className="w-[8.575rem] h-[0.5625rem] custom-height-mq:w-[15rem] custom-height-mq:h-[1rem]"
              loading="lazy"
            />
            <div className="flex-grow" />
            <img
              src={ChemaFoamIcon}
              alt="Copyright"
              className="w-[5.75rem] h-[5rem] custom-height-mq:w-[8.875rem] custom-height-mq:h-[7.6875rem] absolute bottom-[4rem] custom-height-mq:bottom-[3rem] right-[1rem] custom-height-mq:right-[3rem]"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    )
  );
}

export default MainFooter;
