import ContactUsButton from '../Main components/ContactUsButton';
import Header from '../Main components/Header/Header';
import MainFooter from '../Main components/MainFooter/MainFooter';
import MainHeader from '../Main components/MainHeader/MainHeader';
import NavigateTopButton from '../Main components/NavigateTopButton';
import InquiriesCard from './components/InquiriesCard/InquiriesCard';
import CallUsIcon from './assets/CallUs.svg';
import FollowUsIcon from './assets/FollowUs.svg';
import VisitUsIcon from './assets/Location.svg';
import DistCard from './components/DistCard';
import VideosHeader from '../Videos/components/VideosHeader';
import ProductsIcon from './assets/Products.svg';
import BriefcaseIcon from './assets/Briefcase.svg';
import FeedbackIcon from './assets/Feedback.svg';
import SuppliersIcon from './assets/Suppliers.svg';
import OthersIcon from './assets/Other.svg';
import MenuItem from '../About Us/components/MenuItem/MenuItem';
import VisitUsContent from './components/InquiriesCard/VisitUsContent';
import CallUsContent from './components/InquiriesCard/CallUsContent';
import FollowUsContent from './components/InquiriesCard/FollowUsContent';
import CalcModalButton from '../Main components/Modals/CalcModalButton';
import ContactUsContent from './components/ContactUsContent';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import MobileBottomNavBar from '../Main components/MobileBottomNavBar/MobileBottomNavBar';
import HouseIcon from '../Main components/MobileBottomNavBar/assets/HomeIcon.svg';
import AppIcon from '../Main components/MobileBottomNavBar/assets/AppsIcon.svg';
import ProductIcon from '../Main components/MobileBottomNavBar/assets/ProductIcon.svg';
import ContactIcon from '../Main components/MobileBottomNavBar/assets/ContactIcon.svg';
import MoreIcon from '../Main components/MobileBottomNavBar/assets/MoreIcon.svg';
import {
  useGetPageDataQuery,
  useSubscribeEmailMutation,
} from '../../store/apiService';
import { message } from 'antd';
import { useEffect, useState } from 'react';

function Contact() {
  const [email, setEmail] = useState('');
  const currentLanguage = useSelector(
    (state: RootState) => state.language.language
  );
  const { t } = useTranslation();
  const { data, error, isLoading, refetch } = useGetPageDataQuery('contactUs', {
    skip: false,
  });

  const [subscribeEmail, { isLoading: isSubscribing, isSuccess, isError }] =
    useSubscribeEmailMutation();

  const handleSubscribe = () => {
    subscribeEmail({ email });
  };

  useEffect(() => {
    refetch();
  }, [currentLanguage, refetch, error]);

  useEffect(() => {
    if (isSuccess) {
      message.success(t('Subscription successful!'));
    } else if (isError) {
      message.error(t('Subscription failed. Please enter valid e-mail.'));
    }
  }, [isSuccess, isError]);

  const BottomNavBarIcons = [
    HouseIcon,
    AppIcon,
    ProductIcon,
    ContactIcon,
    MoreIcon,
  ];
  const BottomNavBarTitles = [
    t('Home'),
    t('Applications'),
    t('Products'),
    t('Contact Us'),
    t('More'),
  ];

  return (
    data && (
      <div className="flex flex-col">
        <Header Active="Contact Us" />
        <MobileBottomNavBar
          icons={BottomNavBarIcons}
          titles={BottomNavBarTitles}
          active={t('Contact Us')}
        />
        <NavigateTopButton />
        <MainHeader title={t('Contact Us')} page={t('Contact Us')} />
        <div
          className={`w-screen py-[2rem] gap-[2rem] custom-height-mq:py-[5rem] custom-height-mq:gap-[5rem] flex flex-col justify-center items-center  ${
            currentLanguage === 'ar' ? 'rtl' : 'ltr'
          } `}
        >
          <div className="flex flex-col w-[22rem] custom-height-mq:w-[75rem] gap-9">
            <VideosHeader
              first={t('For your Inquiries -')}
              rest={t('Contact us in your preferred way.')}
              contact={true}
            />
            <div className="w-full flex flex-col items-center custom-height-mq:items-start gap-5 custom-height-mq:gap-0 custom-height-mq:flex-row justify-between">
              <InquiriesCard
                icon={VisitUsIcon}
                title={t('Visit Us')}
                content={
                  <VisitUsContent
                    factoryAddress={data['inquiries'][0]['factory_address']}
                    officeAddress={data['inquiries'][0]['office_address']}
                  />
                }
              />
              <InquiriesCard
                icon={CallUsIcon}
                title={t('Call Us')}
                content={<CallUsContent hotLine={'0221276230'} />}
              />
              <InquiriesCard
                icon={FollowUsIcon}
                title={t('Follow Us')}
                content={
                  <FollowUsContent
                    YTURL={data['inquiries'][0]['youtube_page_url']}
                    FBURL={data['inquiries'][0]['facebook_page_url']}
                    LIURL={data['inquiries'][0]['linkedin_page_url']}
                  />
                }
              />
            </div>
          </div>
          <div className="flex flex-col w-[22rem] custom-height-mq:w-[75rem] gap-9">
            <VideosHeader
              first={t('Distributors -')}
              rest={t('Contact your nearest distributor.')}
              contact={true}
            />
            <div className="w-full flex flex-col gap-8 items-center justify-center custom-height-mq:flex-row custom-height-mq:justify-start custom-height-mq:items-start">
              {Object.entries(
                data.distributions as { [key: string]: any[] }
              ).map(([region, details]) => (
                <DistCard
                  key={region}
                  icon={CallUsIcon}
                  title={region}
                  details={details}
                />
              ))}
            </div>
          </div>
          <div className="flex flex-col w-[22rem] custom-height-mq:w-[75rem] gap-9">
            <VideosHeader
              first={t('Contact Forms -')}
              rest={t('Your question is related to:')}
              contact={true}
            />
            <div className="flex flex-col gap-5">
              <MenuItem
                Icon={ProductsIcon}
                title={t('Products and Solutions')}
                Content={<ContactUsContent endpoint={'Products-solutions'} />}
                ContactUs={true}
              />
              <MenuItem
                Icon={BriefcaseIcon}
                title={t('Working at Chema Foam')}
                Content={<ContactUsContent endpoint={'Working-at-Chema'} />}
                ContactUs={true}
              />
              <MenuItem
                Icon={FeedbackIcon}
                title={t('Feedback on our online services')}
                Content={<ContactUsContent endpoint={'Feedback'} />}
                ContactUs={true}
              />
              <MenuItem
                Icon={SuppliersIcon}
                title={t('Suppliers & Partners')}
                Content={<ContactUsContent endpoint={'Suppliers'} />}
                ContactUs={true}
              />
              <MenuItem
                Icon={OthersIcon}
                title={t('Other')}
                Content={<ContactUsContent endpoint={'Other'} />}
                ContactUs={true}
              />
            </div>
            <div className="font-bold pl-[1rem] custom-height-mq:pl-[3rem] text-xs custom-height-mq:text-xl text-[#5A5A5A]">
              {t(
                'Fields With an Asterisk (*) are required fields, which must be filled in.'
              )}
            </div>
          </div>
          <div className="flex flex-col w-[22rem] custom-height-mq:w-[75rem] gap-9">
            <VideosHeader
              first={t('To share our latest news -')}
              rest={t('Subscribe to us with your email.')}
              contact={true}
              subscription={true}
            />
            <div className="h-[6.25rem] custom-height-mq:h-[11.75rem] w-full bg-[#FBAD4A] bg-opacity-10 flex justify-center items-center ">
              <div className="flex flex-row">
                <input
                  type="email"
                  name="email"
                  placeholder="youremail123@gmail.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className={`w-[11.25rem] h-[2.25rem] custom-height-mq:w-[38.6875rem] custom-height-mq:h-[3.75rem] text-xs custom-height-mq:text-2xl font-[300] bg-[#D7D7D7] ${
                    currentLanguage === 'ar'
                      ? 'pr-[0.7rem] custom-height-mq:pr-[2rem]'
                      : 'pl-[0.7rem] custom-height-mq:pl-[2rem]'
                  } `}
                />
                <CalcModalButton
                  bgColor={'bg-[#FBAD4A]'}
                  hoverBgColor={'bg-[#1C4585]'}
                  TextColor={'text-[#1C4585]'}
                  hoverTextColor={'text-white'}
                  title={t('Subscribe')}
                  onClick={handleSubscribe}
                />
              </div>
            </div>
          </div>
        </div>
        <MainFooter />
      </div>
    )
  );
}

export default Contact;
