import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import store from './store';

const apiService = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://web.chema-foam.com/api/',
  }),
  endpoints: (builder) => ({
    getPageData: builder.query({
      query: (pageName) => {
        const language = store.getState().language.language;
        return `page/${pageName}/${language}`;
      },
    }),
    getAwardsData: builder.query({
      query: (pageName) => `Awards/${pageName}`,
    }),
    getApplicationData: builder.query({
      query: (id) => `page/Application/show/${id}`,
    }),
    getProductsData: builder.query({
      query: (id) => `page/Product/show/${id}`,
    }),
    getSystemsData: builder.query({
      query: (id) => `page/Systems/show/${id}`,
    }),
    getBlogsData: builder.query({
      query: () => {
        const language = store.getState().language.language;
        return `page/blogs/${language}`;
      },
    }),
    getBlogDetails: builder.query({
      query: (id) => `page/blogs_details/${id}`,
    }),
    postFormData: builder.mutation({
      query: (formData) => {
        const form = new FormData();
        Object.keys(formData).forEach((key) => {
          form.append(key, formData[key]);
        });
        return {
          url: `ContactUs/forms/${formData.formType}`,
          method: 'POST',
          body: form,
          formData: true,
        };
      },
    }),
    subscribeEmail: builder.mutation({
      query: ({ email }) => {
        const form = new FormData();
        form.append('email', email);
        return {
          url: 'ContactUs/subscribe-email',
          method: 'POST',
          body: form,
        };
      },
    }),
    postAwardsData: builder.mutation({
      query: (formData) => {
        const form = new FormData();
        Object.keys(formData).forEach((key) => {
          form.append(key, formData[key]);
        });
        return {
          url: `Awards/${formData.formType}`,
          method: 'POST',
          body: form,
          formData: true,
        };
      },
    }),
    search: builder.query({
      query: (value) => `search/${value}`,
    }),
  }),
});

export const {
  useGetPageDataQuery,
  useGetAwardsDataQuery,
  useGetApplicationDataQuery,
  useGetProductsDataQuery,
  useGetSystemsDataQuery,
  useGetBlogsDataQuery,
  useGetBlogDetailsQuery,
  usePostFormDataMutation,
  useSubscribeEmailMutation,
  usePostAwardsDataMutation,
  useSearchQuery,
} = apiService;

export default apiService;
